export const sports = [
    { id: '4', name: 'cricket' },
    { id: '1', name: 'soccer' },
    { id: '2', name: 'tennis' },
    { id: '6', name: 'casino' }
]

export const filter = [
    { value: 'All', label: 'All' },
    { value: 'Cricket', label: 'Cricket' },
    { value: 'Soccer', label: 'Soccer' },
    { value: 'Tennis', label: 'Tennis' },
    { value: 'casino', label: 'Casino' }
]

export const Games = {
    ROULETTE: 'ROULETTE'
}

export const CasinoURLS = {
    ROULETTE: '/casino/roulette'
}